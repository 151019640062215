import { ActionButton, Form, Button, Line, Toast } from "components/commons";
import { ModuleWrapper } from "components/fragments";
import { HeaderC } from "components/headers";
import { useApi, useForm, useModal, useMount, useRouter } from "hooks";
import { Path } from "paths";
import React, { useCallback, useContext, useMemo } from "react";
import lang from "translations";
import BasicInfoForm from "./basic-info-form";
import initialFormState from "./product-form.state";
import { searchCategory } from "apis/category.api";
import { getLocations } from "apis/location.api";
import { VenueContext } from "contexts";
import LocationForm from "./location-form";
import InventoryForm from "./inventory-form";
import VariantForm from "./variant-form";
import PricingAndTaxesForm from "./pricing-and-taxes-form";
import { getSupplyItems, getMewsIntegrationStatus, getAccountCodes } from "apis";
import { parseMoneyToNumber } from "services/money.service";
import { getTaxes } from "apis/tax.api";
import { getMeasurements } from "apis/measurement.api";
import { parseAmountToNumber } from "services";
import InventoryType from "enums/inventory-type";
import { ProductType, StyleType } from "enums";
import DeleteSingleProductModal from "../delete-single-product-modal/delete-single-product-modal";
import { mixpanel, TrackEvent } from "mixpanel";
import { accountingResponse } from "mappers/accounting.mapper";
import { mapAccountOptions } from "services/accounting.service";
import { useEffect } from "react";
import { useState } from "react";
const ProductForm = ({
  error,
  title,
  initialState = undefined,
  onSubmit,
  submitting,
  isEdit,
  onDeleteProduct,
  productId,
}) => {
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;
  const { history, location } = useRouter();
  const unsaveChangesModal = useModal();
  const deleteProductModal = useModal();
  const [supplyUnitLoaded, setSupplyUnitLoaded] = useState(false);

  const { state: locationState } = location || {};

  const formState = useMemo(() => {
    return initialFormState(initialState);
  }, [initialState]);

  const form = useForm({
    initialState: formState,
  });

  const {
    dirty,
    modifyField,
    validateField,
    fields,
    modifyForm,
    submitForm,
    getFormValues,
    applyFieldErrors,
  } = form;

  const goToList = useCallback(() => {
    history.push(Path.PRODUCT, {
      page: locationState?.page,
      categories: locationState?.categories,
    });
  }, [history, locationState]);

  const leavePage = useCallback(() => {
    if (dirty) {
      unsaveChangesModal.show({
        ok: () => {
          goToList();
          unsaveChangesModal.close();
        },
      });
      return;
    }
    goToList();
  }, [dirty, unsaveChangesModal, goToList]);

  const requestCategories = useApi({
    api: searchCategory,
    params: {
      venueId,
      page: 1,
      itemsPerPage: null,
      withUnCategorized: false,
    },
    mapper: {
      text: {
        key: "categoryName",
      },
      value: {
        key: "categoryId",
      },
    },
    isArray: true,
    handleOwnError: true,
  });

  const requestMeasurement = useApi({
    api: getMeasurements,
    params: {
      venueId,
    },
    mapper: {
      text: {
        key: "unit",
      },
      value: {
        key: "measurementId",
      },
    },
    isArray: true,
    handleOwnError: true,
  });

  const requestTax = useApi({
    api: getTaxes,
    params: {
      venueId,
    },
    mapper: {
      text: {
        key: "taxName",
      },
      value: {
        key: "taxId",
      },
      _keys: ["taxDefault", "taxPercentage"],
    },
    isArray: true,
    handleOwnError: true,
  });

  const requestSupplyItems = useApi({
    api: getSupplyItems,
    params: {
      venueId,
      page: 1,
      itemsPerPage: null,
    },
    mapper: {
      text: {
        key: "productName",
      },
      value: {
        key: "productSkuId",
      },
      _keys: ["supplyCost", "measurement", "sku", "bargainAllowed", "bargainRequiresApproval"],
    },
    isArray: true,
    handleOwnError: true,
  });

  const requestLocations = useApi({
    api: getLocations,
    params: {
      venueId,
      page: 1,
      itemsPerPage: null,
    },
    mapper: {
      text: {
        key: "locationName",
      },
      value: {
        key: "locationId",
      },
    },
    isArray: true,
    handleOwnError: true,
  });

  const { request: mewsRequest } = useApi({
    api: getMewsIntegrationStatus,
    params: {
      venueId: venue?.venueId,
    },
    mapper: {
      id: { key: "id" },
      integrationType: { key: "integrationType" },
    },
    handleOwnError: true,
  });

  const {
    request: accountRequest,
    mappedData: accountListMappedData,
    loading: accountLoading,
  } = useApi({
    api: getAccountCodes,
    params: {
      businessId: venueId,
    },
    isArray: true,
    mapper: accountingResponse,
    handleOwnError: true,
  });

  useMount(async () => {
    accountRequest();
    mewsRequest();
  });

  useMount(() => {
    requestCategories.request();
    requestLocations.request();
    requestMeasurement.request();
    requestTax.request();
    requestSupplyItems.request();

    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.createProduct,
    });
  });

  const actionButton = (showLine) => (
    <ActionButton
      loading={submitting}
      primary={{
        loading: submitting,
        disabled: submitting || !dirty,
        onClick: () => {
          mixpanel.track(TrackEvent.Clickedbutton, {
            Button: lang.saveProduct,
            Page: lang.createProduct,
          });

          submitForm(submit);
        },
      }}
      secondary={{
        onClick: leavePage,
        text: lang.cancel,
      }}
      showLine={showLine}
    />
  );

  const submit = useCallback(async () => {
    const formValues = getFormValues();
    let params = {
      imageLink: formValues.productLogo,
      productName: formValues.productName,
      description: formValues.description,
      revenueAccountCodeId: formValues.revenueAccountCodeId,
      cogsAccountCodeId: formValues.cogsAccountCodeId,
      inventoryTracked: false,
      creationType: formValues.inventoryType,
      categories: formValues.category?.length
        ? formValues.category.map(({ text, value }) => {
            return { categoryName: text, categoryId: value };
          })
        : [],
      taxId: formValues.hasTax ? Number(formValues.tax) : null,
      sellableInPos: formValues.activePos,
      locationIds: formValues.location?.length
        ? formValues.location.map(({ value }) => {
            return value;
          })
        : [],
      isActiveAllLocations: fields.location.isAll,
      isActiveAllCategories: fields.category.isAll,
      isActiveMobileOrdering: formValues.activeOnlineMenu,
      siigoAccountGroupId: formValues.siigoAccountGroupId,
    };

    if (!formValues.hasVariants) {
      params = {
        ...params,
        sku: formValues.sku,
        retailPrice: parseMoneyToNumber(formValues.retailPrice).value,
        supplyPrice: parseMoneyToNumber(formValues.supplyCost).value,
        bargainAllowed: formValues.bargainAllowed,
        bargainRequiresApproval: formValues.bargainRequiresApproval,
      };
      if (params.creationType === InventoryType.AssembleProduct) {
        params.compositions = formValues.supplyItems?.map((supplyItem) => {
          return {
            productSkuId: supplyItem.item,
            quantity: parseAmountToNumber(supplyItem.quantity),
          };
        });
      } else {
        params = {
          ...params,
          measurementId: Number(formValues.supplyUnit),
          sellingQuantity: parseAmountToNumber(formValues.sellingQuantity),
          stockWeightPerPiece: parseAmountToNumber(formValues.supplyQuantity),
          supplyItemProductSkuId: Number(formValues.supplyItem),
        };
      }
    } else {
      params.productSkus = formValues.variants?.map((variant) => {
        let productSkuObj = {};
        if (params.creationType === InventoryType.WholeProduct && !isEdit) {
          productSkuObj = {
            ...productSkuObj,
            measurementId: Number(variant.supplyUnit),
            sellingQuantity: parseAmountToNumber(variant.sellingQuantity),
            stockWeightPerPiece: parseAmountToNumber(variant.supplyQuantity),
          };
        } else {
          productSkuObj.compositions = variant.supplyItems?.map((supplyItem) => {
            return {
              productSkuId: supplyItem.item,
              quantity: parseAmountToNumber(supplyItem.quantity),
            };
          });
          if (params.creationType === InventoryType.WholeProduct) {
            productSkuObj = {
              ...productSkuObj,
              measurementId: Number(variant.supplyUnit),
              sellingQuantity: parseAmountToNumber(variant.sellingQuantity),
              quantity: parseAmountToNumber(variant.sellingQuantity),
              stockWeightPerPiece: parseAmountToNumber(variant.supplyQuantity),
            };
          }
        }
        if (variant.productSkuId) {
          productSkuObj.productSkuId = variant.productSkuId;
        }
        return {
          ...productSkuObj,
          imageLink: variant.logo,
          sku: variant.sku,
          supplyPrice: parseMoneyToNumber(variant.supplyCost).value,
          retailPrice: parseMoneyToNumber(variant.retailPrice).value,
          variants: variant.attribute,
          status: variant.status,
          bargainAllowed: variant.bargainAllowed,
          bargainRequiresApproval: variant.bargainRequiresApproval,
        };
      });
    }

    try {
      if (!params.isActiveAllLocations && params.locationIds.length === 0) {
        applyFieldErrors({
          location: lang.pleaseSelectLocation,
        });
        return;
      }
      const res = await onSubmit({
        ...params,
        venueId,
      });
      Toast({
        content: res.message,
        success: true,
        icon: "check",
      }).open();
      goToList();
    } catch ({ handleError, code, metadata }) {
      const error = {
        3006: () => {
          applyFieldErrors({
            productName: lang.thisProductNameAlreadyExists,
          });
        },
        3001: () => {
          if (!formValues.hasVariants) {
            applyFieldErrors({
              sku: lang.thisSkuExists,
            });
          } else {
            const variants = fields.variants.value.map((val) => {
              const obj = {};
              if (val.value.sku.value === metadata.sku) {
                obj.sku = {
                  ...val.value.sku,
                  invalid: true,
                  message: lang.thisSkuExists,
                };
              }
              return {
                ...val,
                value: {
                  ...val.value,
                  ...obj,
                },
              };
            });
            modifyField("variants", {
              value: variants,
            });
          }
        },
      };
      if (error[code]) {
        return error[code]();
      }
      return handleError();
    }
  }, [
    getFormValues,
    fields.location.isAll,
    fields.category.isAll,
    fields.variants.value,
    isEdit,
    onSubmit,
    venueId,
    goToList,
    applyFieldErrors,
    modifyField,
  ]);

  const accountOptions = useMemo(() => {
    return mapAccountOptions(accountListMappedData);
  }, [accountListMappedData]);

  const formField = useMemo(() => {
    const formItems = {};
    [
      "productLogo",
      "productName",
      "description",
      "category",
      "revenueAccountCodeId",
      "cogsAccountCodeId",
      "activePos",
      "activeOnlineMenu",
      "location",
      "inventoryType",
      "sku",
      "generatedSku",
      "supplyUnit",
      "supplyQuantity",
      "sellingQuantity",
      "supplyItem",
      "hasVariants",
      "hasTax",
      "supplyCost",
      "markUp",
      "retailPrice",
      "variants",
      "attributeAndOptions",
      "supplyItems",
      "tax",
      "attributes",
      "bargainAllowed",
      "bargainRequiresApproval",
      "siigoAccountGroupId",
    ].forEach((key) => {
      formItems[key] = {
        ...fields[key],
        onChange: modifyField,
        disabled: key === "bargainAllowed" || key === "bargainRequiresApproval",
        validateField: () => {
          validateField(key, fields[key]);
        },
      };
    });
    return formItems;
  }, [fields, modifyField, validateField]);

  const hasSelectedInventoryType = Boolean(formField.inventoryType.value);

  useEffect(() => {
    if (requestSupplyItems.mappedData && formField?.supplyItem) {
      requestSupplyItems.mappedData.forEach((data) => {
        if (
          !supplyUnitLoaded &&
          data.value === formField?.supplyItem.value &&
          formField?.supplyUnit.value !== data?.measurement?.unit
        ) {
          modifyField("supplyUnit", { unit: data?.measurement?.unit });
          setSupplyUnitLoaded(true);
        }
      });
    }
  }, [
    supplyUnitLoaded,
    formField,
    modifyField,
    requestSupplyItems.mappedData,
    formField.supplyItem,
  ]);

  return (
    <ModuleWrapper
      error={error || requestCategories.error}
      header={
        <HeaderC
          returnText={lang.products}
          title={title}
          onClick={leavePage}
          action={error ? null : actionButton()}
        />
      }
    >
      <Form unsaveChangesModal={unsaveChangesModal} isPrompt={dirty}>
        <BasicInfoForm
          form={formField}
          categories={requestCategories.mappedData}
          categoriesLoading={requestCategories.loading}
          accountingOptions={accountOptions}
          loading={accountLoading}
          modifyField={modifyField}
        />
        <LocationForm
          form={formField}
          locations={requestLocations.mappedData}
          locationLoading={requestLocations.loading}
        />
        <InventoryForm
          form={formField}
          hasSelectedInventoryType={hasSelectedInventoryType}
          requestSupplyItems={requestSupplyItems}
          modifyForm={modifyForm}
          requestMeasurement={requestMeasurement}
          isEdit={isEdit}
        />
        {((!isEdit && hasSelectedInventoryType) ||
          (isEdit && fields.type.value === ProductType.Variant)) && (
          <VariantForm
            form={formField}
            modifyForm={modifyForm}
            requestMeasurement={requestMeasurement}
            requestTax={requestTax}
            requestSupplyItems={requestSupplyItems}
            isEdit={isEdit}
          />
        )}
        {hasSelectedInventoryType && (
          <PricingAndTaxesForm form={formField} modifyForm={modifyForm} requestTax={requestTax} />
        )}
      </Form>
      {!onDeleteProduct && !productId ? (
        actionButton(true)
      ) : (
        <div>
          <Line />
          <div className="flex justify-between items-center ">
            <Button type={StyleType.Danger} onClick={() => deleteProductModal.show()}>
              {lang.deleteAProduct}
            </Button>
            <div className="flex items-center">{actionButton(true)}</div>
            <DeleteSingleProductModal
              productId={productId}
              ok={onDeleteProduct}
              {...deleteProductModal}
            />
          </div>
        </div>
      )}
    </ModuleWrapper>
  );
};

export default ProductForm;
